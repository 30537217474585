import { User } from '@/lib/features/User/model/user.model';
import fontkit from '@pdf-lib/fontkit';
import { PDFDocument } from 'pdf-lib';

export interface RegistrationData extends User {
	date: Date
}

export const generateSignUpPdf = async (user: RegistrationData) => {
	const response = await fetch('/prihlaska.pdf');
	const buffer = await response.arrayBuffer();
	const existingPdfDocBytes = new Uint8Array(buffer);

	// Load font 
	const openSansBytes = await fetch('/sans.ttf').then((res) => res.arrayBuffer());

	const pdfDoc = await PDFDocument.load(existingPdfDocBytes)

	pdfDoc.registerFontkit(fontkit);
	const openSansFont = await pdfDoc.embedFont(openSansBytes);

	// Get the first page of the document
	const pages = pdfDoc.getPages()
	const firstPage = pages[0]

	// Name 
	firstPage.drawText(`${user.info.firstName} ${user.info.lastName}`, {
		x: 160,
		y: 721,
		size: 10,
		font: openSansFont
	})

	// Birth date and place
	firstPage.drawText(`${new Date(user.info.birthDate).toLocaleDateString("sk")}, ${user.info.birthPlace}`, {
		x: 193,
		y: 702,
		size: 10,
		font: openSansFont
	})

	// Address
	firstPage.drawText(`${user.address.street}, ${user.address.city}, ${user.address.zipCode}`, {
		x: 149,
		y: 683,
		size: 10,
		font: openSansFont
	})

	// Identification number
	firstPage.drawText(`${user.info.identificationNumber}`, {
		x: 129,
		y: 664,
		size: 10,
		font: openSansFont
	})

	// Email
	firstPage.drawText(`${user.contact.email}`, {
		x: 315,
		y: 664,
		size: 10,
		font: openSansFont
	})


	// Phone
	firstPage.drawText(`${user.contact.phone}`, {
		x: 165,
		y: 645,
		size: 10,
		font: openSansFont
	})


	// Where
	firstPage.drawText(`Zvolene`, {
		x: 95,
		y: 142,
		size: 10,
		font: openSansFont
	})

	// Date
	firstPage.drawText(`${new Date(user.date).toLocaleDateString('sk')}`, {
		x: 240,
		y: 142,
		size: 10,
		font: openSansFont
	})

	// Serialize the PDFDocument to bytes (a Uint8Array)
	const pdfBytes = await pdfDoc.save()
	var bytes = new Uint8Array(pdfBytes);
	var blob = new Blob([bytes], { type: "application/pdf" });
	const docUrl = URL.createObjectURL(blob);
	window.open(docUrl)
}
