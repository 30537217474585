import {RegistrationData} from '@/components/Documents/generated/utils/signup';
import fontkit from '@pdf-lib/fontkit';
import {ColorTypes, PDFDocument, PDFPageDrawTextOptions, rgb} from 'pdf-lib';
import {convertToPdfCoordinates} from "@/components/Documents/generated/utils/coordinates";
import {convertToNormalizedRGB} from "@/components/Documents/generated/utils/color";

const FONT_SIZE = 10;

interface DrawTextOptionsParams {
  x: number;
  y: number;
}

export const generateRequestPdf = async (user: RegistrationData) => {
  const response = await fetch('/ziadost-nova.pdf');
  const buffer = await response.arrayBuffer();
  const existingPdfDocBytes = new Uint8Array(buffer);

  // Load font
  const openSansBytes = await fetch('/sans.ttf').then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfDocBytes)

  pdfDoc.registerFontkit(fontkit);
  const openSansFont = await pdfDoc.embedFont(openSansBytes);


  // Get the first page of the document
  const pages = pdfDoc.getPages()
  const firstPage = pages[0]
  const secondPage = pages[1]
  const pageHeight = firstPage.getHeight()

  /**
   * Draw prepares options for drawText method
   * @param params
   */
  const drawTextOptions = (params: DrawTextOptionsParams): PDFPageDrawTextOptions => ({
    ...convertToPdfCoordinates({x: params.x, y: params.y, height: pageHeight}),
    size: FONT_SIZE,
    font: openSansFont,
    color: rgb(...convertToNormalizedRGB([19, 33, 125])),
  })

  // Print the data into the document ////////////////////////////////
  // Last name
  firstPage.drawText(`${user.info.firstName}`, drawTextOptions({
    x: 98.1,
    y: 258.2,
  }))

  // Last name
  firstPage.drawText(`${user.info.lastName}`, drawTextOptions({
    x: 317.8,
    y: 258.2,
  }))

  // Birth date
  firstPage.drawText(`${new Date(user.info.birthDate).toLocaleDateString("sk")}`, drawTextOptions({
    x: 148.7,
    y: 293.5,
  }))

  // Birth place
  firstPage.drawText(`${user.info.birthPlace}`, drawTextOptions({
    x: 346.7,
    y: 293.5,
  }))


  // Identification number
  firstPage.drawText(`${user.info.identificationNumber}`, drawTextOptions({
    x: 123.9,
    y: 310.7,
  }))


  // Address
  firstPage.drawText(`${user.address.street}, ${user.address.city}, ${user.address.zipCode}`, drawTextOptions({
    x: 260.8,
    y: 328.4,
  }))

  // SignLocation
  firstPage.drawText(`Zvolene`, drawTextOptions({
    x: 123.1,
    y: 519.6,
  }))

  // SignDate
  firstPage.drawText(`${new Date(user.date).toLocaleDateString("sk")}`, drawTextOptions({
    x: 278,
    y: 520,
  }))


  if (user.guardian) {
    // Guardian first name
    firstPage.drawText(`${user.guardian.firstName}`, drawTextOptions({
        x: 98.3,
        y: 588,
      }
    ))

    // Guardian last name
    firstPage.drawText(`${user.guardian?.lastName}`, drawTextOptions({
      x: 295,
      y: 588,
    }))

    // Guardian identification number
    firstPage.drawText(`${user.guardian?.identificationNumber}`, drawTextOptions({
      x: 133,
      y: 612,
    }))

    // Guardian Sign location
    firstPage.drawText(`Zvolene`, drawTextOptions({
      x: 123,
      y: 673,
    }))

    // Guardian Sign date
    firstPage.drawText(`${new Date(user.date).toLocaleDateString("sk")}`, drawTextOptions({
      x: 274,
      y: 673,
    }))
  }

  // Doctor section ////////////////////////////////

  // Name
  secondPage.drawText(`${user.info.firstName}`, drawTextOptions({
    x: 93.5,
    y: 193,
  }))

  // Last name
  secondPage.drawText(`${user.info.lastName}`, drawTextOptions({
    x: 288,
    y: 193,
  }))

  // Birth date
  secondPage.drawText(`${new Date(user.info.birthDate).toLocaleDateString("sk")}`, drawTextOptions({
    x: 147,
    y: 210,
  }))


// Serialize the PDFDocument to bytes (a Uint8Array)
const pdfBytes = await pdfDoc.save({})
var bytes = new Uint8Array(pdfBytes);
var blob = new Blob([bytes], {type: "application/pdf"});
return URL.createObjectURL(blob);
}
