interface ConvertedCoordinatesParams {
  x: number;
  y: number;
  height: number;
}

interface ConvertCoordinatesReturn {
  x: number;
  y: number;
}

export function convertToPdfCoordinates(params: ConvertedCoordinatesParams): ConvertCoordinatesReturn {
  // PDF's x remains the same as it also starts from the left
  let pdfX: number = params.x;

  // PDF's y is calculated by subtracting the input y from the height of the PDF
  let pdfY: number = params.height - params.y;

  return {
    x: pdfX,
    y: pdfY,
  }
}
