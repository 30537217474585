import {Button} from "@material-ui/core"
import React, {useEffect} from "react"
import {HiDownload} from "react-icons/hi"
import {generateRequestPdf} from "../utils/request"
import {RegistrationData} from "../utils/signup"

export interface RequestDocumentProps {
  className?: string
  data: RegistrationData
}

export const RequestDocument = (props: RequestDocumentProps) => {
  const {className = "", data} = props

  const handleDownload = () => {
    generateRequestPdf(data).then((url) => {
      window.open(url, "_blank");
    })
  }

  useEffect(() => {
    handleDownload()
  }, [])

  return (
    <div className="">
      <Button
        startIcon={<HiDownload/>}
        onClick={handleDownload}
        style={{}}
        variant="contained"
        color="primary"
      >
        Žiadosť
      </Button>
    </div>
  )
}
