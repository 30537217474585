import { RequestDocument } from "@/components/Documents/generated/components/RequestDocument"
import { RegistrationData } from "@/components/Documents/generated/utils/signup"
import { Typography } from "@material-ui/core"
import { decode } from "js-base64"
import querystring from "query-string"
import React from "react"
import { SignUpDocument } from "../../components/Documents/generated/components/SignUpDocument"
import Layout from "../../components/shared/Layout/layout"

export interface PrihlaskaPageProps {
  className?: string
  location: any
}

export const SignUpPage = (props: PrihlaskaPageProps) => {
  const { className = "", location } = props
  const { d } = querystring.parse(location.search)

  let user: RegistrationData | null = null
  if (d) {
    user = JSON.parse(decode(d as string))
  }

  return (
    <Layout>
      <section className="py-32 flex flex-col justify-center items-center">
        <header className="text-black text-3xl mb-16 w-3/4 text-center">
          <Typography variant="h3" color="primary" className="opacity-80 text-4xl">
            Ahoj,{" "}
            <Typography variant="h3" className="inline-flex" color="secondary">
              {user?.info?.firstName}
            </Typography>{" "}
            nižšie sme pre teba pripravili všetky dokumenty, ktoré budeš potrebovať:
          </Typography>
        </header>
        <div className="buttons flex space-x-4 lg:space-x-8">
          {user && (
            <>
              <SignUpDocument data={user} />
              <RequestDocument data={user} />
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default SignUpPage
