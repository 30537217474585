import { Button } from "@material-ui/core"
import React from "react"
import { HiDownload } from "react-icons/hi"
import { generateSignUpPdf, RegistrationData } from "../utils/signup"

export interface SignUpDocumentProps {
  className?: string
  data: RegistrationData
}

export const SignUpDocument = (props: SignUpDocumentProps) => {
  const { className = "", data } = props

  return (
    <div className="">
      <Button
        startIcon={<HiDownload />}
        onClick={() => generateSignUpPdf(data)}
        style={{}}
        variant="contained"
        color="primary"
      >
        Prihláška
      </Button>
    </div>
  )
}
